const PopularData = [
    {
      id: 1,
      image: "images/Upcoming_02.png",
      date: "25 NOVEMBER 2022",
      name: "BHEDIYA",
    },
    {
      id: 2,
      image: "images/Upcoming_01.png",
      date: "",
      name: "TEHRAN",
    },
    {
      id: 3,
      image: "images/Upcoming_02.png",
      date: "25 NOVEMBER 2022",
      name: "BHEDIYA",
    },
    {
      id: 4,
      image: "images/Upcoming_01.png",
      date: "25 NOVEMBER 2022",
      name: "TEHRAN",
    },
    {
      id: 5,
      image: "images/Upcoming_02.png",
      date: "25 NOVEMBER 2022",
      name: "BHEDIYA",
    },
    {
      id: 6,
      image: "images/Upcoming_01.png",
      date: "25 NOVEMBER 2022",
      name: "TEHRAN",
    },
    
  ]
  
  export default PopularData