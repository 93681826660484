import React from "react"
import "./EmptyFile.css"

const EmptyAwards = () => {
  return (
    <>
      <section className='error'>
        <h1>No Awards</h1>
      </section>
    </>
  )
}

export default EmptyAwards