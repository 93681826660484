import React from "react"
import "./EmptyFile.css"

const EmptyFile = () => {
  return (
    <>
      <section className='error'>
        {/* <h1>Error 404 !</h1> */}
      </section>
    </>
  )
}

export default EmptyFile